import biofoton from 'assets/biofoton-text.png'
import galaxfamily from 'assets/galaxfamily-text.png'
import lightbeauty from 'assets/lightbeauty-text.png'
import odonlight from 'assets/odonlight-text.png'
import expert from 'assets/expert.png'

export const getProductName = productCode =>
({
    bf: 'Biofoton 2',
    gf: 'Galax Family',
    lb: 'Light Beauty',
    ol: 'Odon Light',
    fs: 'Frecuencias Simples',
    se: 'Sistema Experto',
}[productCode])

export const getProductImage = productCode =>
({
    bf: biofoton,
    gf: galaxfamily,
    lb: lightbeauty,
    ol: odonlight,
}[productCode])

export const getProductSections = productCode =>
({
    bf: ['Programas', 'Frecuencias', 'Sesiones'],
    gf: ['Emociones', 'Sesiones'],
    lb: ['Local', 'Emociones', 'Facial'],
    ol: ['Sesiones', 'Emociones'],
    fs: ['Frecuencias Simples'],
    se: ['Mental', 'Emocional', 'Físico'],
}[productCode])

export const getProductSectionData = (productCode, data) =>
({
    bf: {
        Programas: {
            groups: data?.getgroups?.programs,
            frecs: data?.getfrecsbygroup?.programs,
        },
        Frecuencias: {
            groups: data?.getgroups?.frecuencies,
            frecs: data?.getfrecsbygroup?.frecuencies,
        },
        Sesiones: {
            groups: data?.getsessions,
            frecs: data?.getfrecsbysession,
        },
    },
    gf: {
        Emociones: {
            frecs: data?.getemotions,
        },
        Sesiones: {
            groups: data?.getsessions,
            frecs: data?.getfrecsbysession,
        },
    },
    lb: {
        Local: {
            frecs: data?.local,
        },
        Emociones: {
            frecs: data?.emotions,
        },
        Facial: {
            groups: data?.facial,
            frecs: data?.facial,
        },
    },
    ol: {
        Sesiones: {
            groups: data?.getsessions,
            frecs: data?.getfrecsbysession,
        },
        Emociones: {
            frecs: data?.getemotions,
        },
    },
    se: {
        Mental: {
            groups: data?.getgroups?.mental,
            frecs: data?.getfrecsbygroup?.mental,
        },
        Emocional: {
            groups: data?.getgroups?.emotional,
            frecs: data?.getfrecsbygroup?.emotional,
        },
        Físico: {
            groups: data?.getgroups?.physical,
            frecs: data?.getfrecsbygroup?.physical,
        },
    },
    fs: {
        'Frecuencias Simples': data,
    },
}[productCode])

export const getGroupsByProduct = (productCode, sectionName) =>
({
    bf: {
        Programas: ['getgroups', 'programs'],
        Frecuencias: ['getgroups', 'frecuencies'],
        Sesiones: ['getsessions'],
    },
    gf: { Sesiones: ['getsessions'] },
    lb: { Facial: ['facial'] },
    ol: { Sesiones: ['getsessions'] },
    se: {
        Mental: ['getgroups', 'mental'],
        Emocional: ['getgroups', 'emotional'],
        Físico: ['getgroups', 'physical'],
    },
}[productCode][sectionName])

export const getFrecsByProduct = (productCode, sectionName) =>
({
    bf: {
        Programas: ['getfrecsbygroup', 'programs'],
        Frecuencias: ['getfrecsbygroup', 'frecuencies'],
        Sesiones: ['getfrecsbysession'],
    },
    gf: { Emociones: ['getemotions'], Sesiones: ['getfrecsbysession'] },
    lb: { Local: ['local'], Emociones: ['emotions'], Facial: ['facial'] },
    ol: { Emociones: ['getemotions'], Sesiones: ['getfrecsbysession'] },
    se: {
        Mental: ['getfrecsbygroup', 'mental'],
        Emocional: ['getfrecsbygroup', 'emotional'],
        Físico: ['getfrecsbygroup', 'physical'],
    },
    fs: { 'Frecuencias Simples': ['frecs'] },
}[productCode][sectionName])

export const postGroupsByProduct = (
    data,
    product,
    groupsByProduct,
    frecsByProduct,
    language,
    group,
    newGroup,
) => {
    const newData = {
        ...data,
    }

    console.log('groupsByProduct', groupsByProduct);

    if (groupsByProduct && groupsByProduct[1]) {
        /* for programs and frecuencies */
        console.log('newData postGroupsByProduct', newData);
        /* ATENCION SE UTILIZA group.groupsIndex PORQUE LOS IDS ESTÁN REPETIDOS Y PUEDE QUE BUSQUE OTRO ERRONEAMENTE EJ. MEDITACION */
        newData[product][groupsByProduct[0]][groupsByProduct[1]][language][group.groupsIndex] = newGroup

        if (frecsByProduct[1] && !newData[product][frecsByProduct[0]][frecsByProduct[1]][language][group.groupsIndex]) {
            /* dentro de programas/frecuencias al editar un campo, si no tiene frecuencias lo crea vacío*/
            newData[product][frecsByProduct[0]][frecsByProduct[1]][language][group.groupsIndex] = []
        }

        return newData

    } else {
        /* for sessions */
        /* ATENCION SE UTILIZA group.groupsIndex PORQUE LOS IDS ESTÁN REPETIDOS Y PUEDE QUE BUSQUE OTRO ERRONEAMENTE EJ. MEDITACION */
        newData[product][groupsByProduct[0]][language][group.groupsIndex] = newGroup

        if (frecsByProduct[1] && !newData[product][frecsByProduct[0]][frecsByProduct[1]][language][group.groupsIndex]
        ) {
            newData[product][frecsByProduct[0]][frecsByProduct[1]][language][group.groupsIndex] = []
        }

        return newData
    }
}

export const reorderGroupsByProduct = (
    data,
    product,
    groupsByProduct,
    frecsByProduct,
    language,
    group,
    newGroup,
) => {
    const newData = {
        ...data,
    }

}

export const reorderGroupFrecsByProduct = (
    data,
    product,
    groupsByProduct,
    frecsByProduct,
    language,
    group,
    reorderedGroup,
    reorderedGroupFrecs,
) => {
    const newData = {
        ...data,
    }

    switch (product) {
        case 'lb':
            newData[product][frecsByProduct[0]][language][group.groupsIndex].frequencies = reorderedGroupFrecs;
            break;
    
        default:
            if (groupsByProduct && groupsByProduct[1]) {
                /* programas y frecuencias */
                newData[product][groupsByProduct[0]][groupsByProduct[1]][language][group.groupsIndex] = reorderedGroup;
        
                if (frecsByProduct[1]) {
                    newData[product][frecsByProduct[0]][frecsByProduct[1]][language][group.groupsIndex] = reorderedGroupFrecs;
                }
                
            } else if (groupsByProduct) {
                /* sesiones */
                newData[product][groupsByProduct[0]][language][group.groupsIndex] = reorderedGroup
        
                if (frecsByProduct[1] && !newData[product][frecsByProduct[0]][frecsByProduct[1]][language][group.groupsIndex]) {
                    newData[product][frecsByProduct[0]][frecsByProduct[1]][language][group.groupsIndex] = reorderedGroupFrecs;

                } else {
                    newData[product][frecsByProduct[0]][language][group.groupsIndex] = reorderedGroupFrecs;
                }
            }
            break;
    }

    return newData;
}

export const removeGroupsByProduct = (
    data,
    product,
    groupsByProduct,
    frecsByProduct,
    group,
) => {
    const newData = {
        ...data,
    }

    switch (product) {
        case 'lb':
            newData[product][groupsByProduct[0]]['es'].splice(group.groupsIndex, 1,);
            newData[product][groupsByProduct[0]]['en'].splice(group.groupsIndex, 1,);
            break;
    
        default:
            if (groupsByProduct && groupsByProduct[1]) {
                /* programas y frencuencias */
                newData[product][groupsByProduct[0]][groupsByProduct[1]]['es'].splice(group.groupsIndex, 1,)
                newData[product][groupsByProduct[0]][groupsByProduct[1]]['en'].splice(group.groupsIndex, 1,)
        
                if (frecsByProduct[1]) {
                    newData[product][frecsByProduct[0]][frecsByProduct[1]]['es'].splice(group.groupsIndex, 1,)
                    newData[product][frecsByProduct[0]][frecsByProduct[1]]['en'].splice(group.groupsIndex, 1,)
                }
        
            } else {
                /* sessiones */
                newData[product][groupsByProduct[0]]['es'].splice(group.groupsIndex, 1);
                newData[product][groupsByProduct[0]]['en'].splice(group.groupsIndex, 1);
        
                if (!frecsByProduct[1]) {
                    newData[product][frecsByProduct[0]]['es'].splice(group.groupsIndex, 1);
                    newData[product][frecsByProduct[0]]['en'].splice(group.groupsIndex, 1);
                }
        
            }
            break;
    }

    return newData;
}

export const postFrecByProduct = (
    data,
    product,
    frecsByProduct,
    groupsByProduct,
    language,
    group,
    frec,
    newFrec,
) => {
    const newData = {
        ...data,
    }

    if (!group) {
        /* NO SABEMOS PARA QUE SIRVE
        const indexFound = newData[product][frecsByProduct[0]][language].findIndex(
            ({ Id }) => Id === frec.Id,
        )
        if (indexFound) {
            newData[product][frecsByProduct[0]][language][indexFound] = newFrec
        }
        //newData[product][frecsByProduct[0]][language][frec.frecIndex] = newFrec
        
        console.log('elem',  newData[product][frecsByProduct[0]][language]);
        console.log('product', product);
        console.log('frecsByProduct[0]', frecsByProduct[0]);
        console.log('language', language);
        console.log('frec.frecIndex', frec.frecIndex);
        return newData
        */

    }

    switch (product) {
        case 'lb':

            if (group) {
                if (!newData[product][frecsByProduct[0]][language][group.groupsIndex].frequencies) {
                    newData[product][frecsByProduct[0]][language][group.groupsIndex].frequencies = [];
                }

                newData[product][frecsByProduct[0]][language][group.groupsIndex].frequencies[frec.frecIndex] = newFrec;

            } else {
                newData[product][frecsByProduct[0]][language][frec.frecIndex] = newFrec;
            }
            break;

        default:
            if (groupsByProduct && groupsByProduct[1]) {
                /* for programs and frecuencies */
                if (frecsByProduct[1]) {
                    newData[product][frecsByProduct[0]][frecsByProduct[1]][language][group.groupsIndex][frec.frecIndex] = newFrec;
                }

                if (newData[product][groupsByProduct[0]][groupsByProduct[1]][language].frequencies) {
                    /* EN INGLES NO EXISTEN LAS FRECUENCIAS EN ESE OBJETO */
                    newData[product][groupsByProduct[0]][groupsByProduct[1]][language][group.groupsIndex].frequencies[frec.frecIndex] = newFrec;
                }

            } else {
                /* for sessions */
                if (group) {
                    if (!newData[product][frecsByProduct[0]][language][group.groupFrecsIndex]) {
                        newData[product][frecsByProduct[0]][language][group.groupFrecsIndex] = []
                    }

                    newData[product][frecsByProduct[0]][language][group.groupsIndex][frec.frecIndex] = newFrec;

                } else {
                    newData[product][frecsByProduct[0]][language][frec.frecIndex] = newFrec;
                }
            }
            break;
    }

    return newData;

}

export const removeFrecByProduct = (
    data,
    product,
    frecsByProduct,
    groupsByProduct,
    group,
    frec,
) => {
    const newData = {
        ...data,
    }

    /* NO SABEMOS QUE ES
    if (!group) {
        newData[product][frecsByProduct[0]][language].splice(frec.frecIndex, 1)
        return newData
    }
    */

    switch (product) {
        case 'lb':
            newData[product][frecsByProduct[0]]['es'][group.groupsIndex].frequencies.splice(frec.frecIndex, 1);
            newData[product][frecsByProduct[0]]['en'][group.groupsIndex].frequencies.splice(frec.frecIndex, 1);
            break;
    
        default:
            if (groupsByProduct && groupsByProduct[1]) {
                /* for programs and frecuencies */
                if (frecsByProduct[1]) {
                    newData[product][frecsByProduct[0]][frecsByProduct[1]]['es'][group.groupsIndex].splice(frec.frecIndex, 1);
                    newData[product][frecsByProduct[0]][frecsByProduct[1]]['en'][group.groupsIndex].splice(frec.frecIndex, 1);
                }
                /* EN INGLES NO EXISTEN LAS FRECUENCIAS EN ESE OBJETO */
                newData[product][groupsByProduct[0]][groupsByProduct[1]]['es'][group.groupsIndex].frequencies.splice(frec.frecIndex, 1);
        
            } else {
                /* for sessions */
                if (group) {
                    newData[product][frecsByProduct[0]]['es'][group.groupsIndex].splice(frec.frecIndex, 1);
                    newData[product][frecsByProduct[0]]['en'][group.groupsIndex].splice(frec.frecIndex, 1);
                } else {
                    newData[product][frecsByProduct[0]]['es'].splice(frec.frecIndex, 1);
                    newData[product][frecsByProduct[0]]['en'].splice(frec.frecIndex, 1);
                }
        
            }
            break;
    }

    return newData;

}
