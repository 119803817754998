import { useEffect, useState } from 'react'

import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { useData } from 'contexts/data'
import { useSimpleFrecs } from 'contexts/simplefrecs'
import { getProductImage, getProductName } from 'api'

import AnimateChildren from 'react-animate-children'

import Sections from './sections'
import Box from 'components/box'
import Typography from 'components/typography'
import { useLanguage } from 'contexts/language'

const ProductDashboard = () => {
    const { product } = useParams()

    const productName = getProductName(product)

    const [data] = useData()
    const [simpleFrecs] = useSimpleFrecs()

    const productData = product === 'fs' ? simpleFrecs : data && data[product]

    const [visible, setVisible] = useState(false)

    const [language, setLanguage] = useLanguage()

    const handleLanguageChange = checked => {
        /**
         * * checked: true = en
         * * checked: false = es
         */
        const newLanguage = checked ? 'en' : 'es'

        setLanguage(newLanguage)
    }

    useEffect(() => {
        setTimeout(() => {
            setVisible(true)
        }, 500)

        return () => {
            setVisible(false)
        }
    }, [product])

    if (!visible) {
        return <></>
    }

    const renderLanguageSwitcher = () => {
        return null;
        return (
            <SwitchContainer onChange={e => handleLanguageChange(e.target.checked)}>
                <SwitchInput
                    type="checkbox"
                    defaultChecked={language === 'en' ? true : false}
                />
                <SwitchSlider className="slider round" />
            </SwitchContainer>
        );
    }

    return (
        
        <Container speed={50}>
            <TopContent>
                <Subtitle variant="subtitle">{productName}</Subtitle>

                {productData ? (
                    renderLanguageSwitcher()
                ) : null}
            </TopContent>

            {!productData ? (
                <NoDataContainer>
                    <Typography variant="bodylarge">
                        Todavía no se ha importado ningún archivo JSON para {productName}.
                    </Typography>
                </NoDataContainer>

            ) : (

                <Sections
                    product={{
                        code: product,
                        name: productName,
                        data: productData,
                    }}
                />
            )}
                
        </Container>
    )
}

const Subtitle = styled(Typography)`
  margin: 10px 0 10px 0;
  color: ${({ theme }) => theme.palette.whites[3]};
  width: 50%;
`

const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const Container = styled(AnimateChildren)``

const NoDataContainer = styled(Box)`
  margin-top: 20px;
  text-align: center;
`

const SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.palette.brandLight};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: ${({ theme }) => theme.palette.brand};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${({ theme }) => theme.palette.brand};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(56px);
    -ms-transform: translateX(56px);
    transform: translateX(56px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .slider:after {
    content: 'ES';
    color: white;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 15px;
    font-family: Verdana, sans-serif;
  }

  input:checked + .slider:after {
    content: 'EN';
  }
`
const SwitchInput = styled.input``

const SwitchSlider = styled.span``

export default ProductDashboard
