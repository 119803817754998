import { useState } from 'react'

import styled from 'styled-components'
import Dropzone from 'react-dropzone'

import { useData } from 'contexts/data'
import { getProductImage, getProductName } from 'api'

import AnimateChildren from 'react-animate-children'
import SectionHeader from 'components/section-header'
import Box from 'components/box'
import Typography from 'components/typography'
import Checkbox from 'components/checkbox'
import Button from 'components/button'
import { useToast } from 'contexts/toast'
//import { staticData } from './data.js';

const Config = () => {
    const products = [
        {
            name: 'Biofoton 2',
            code: 'bf',
        },
        {
            name: 'Galax Family',
            code: 'gf',
        },
        {
            name: 'Light Beauty',
            code: 'lb',
        },
        {
            name: 'Odon Light',
            code: 'ol',
        },
        {
            name: 'Sistema Experto',
            code: 'se',
        },
    ]

    const [exportingSelectedProducts, setExportingSelectedProducts] = useState([])

    const [, setToast] = useToast()
    const [data, setData] = useData()

    const handleExportPress = async () => {

        exportingSelectedProducts.map(async product => {
            const fileData = JSON.stringify(data[product])

            const blob = new Blob([fileData], { type: 'text/plain' })

            const url = URL.createObjectURL(blob)

            const link = document.createElement('a')

            link.download = product + '.json'
            link.href = url
            link.click()
        })
    }

    const [isLoading, setIsLoading] = useState(false)

    const [file, setFile] = useState(null)

    const handleImportPress = async () => {

        /* data.js para convertirlo a archivo json 
        const blob = new Blob([JSON.stringify(staticData)], { type: 'text/plain' })
    
          const url = URL.createObjectURL(blob)
    
          const link = document.createElement('a')
    
          link.download = 'prueba.json'
          link.href = url
          link.click()
        return
        */

        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));

        if (!file) {
            setToast({
                message: 'Debes importar un archivo JSON primero',
                duration: 7000,
                status: 'error',
            })
        }

        setIsLoading(true)

        const fileFormat = file[0]?.path.split('.')[file[0]?.path.split('.').length - 1]

        if (fileFormat !== 'json' && fileFormat !== 'txt') {
            console.log(fileFormat)

            setToast({
                message: `El archivo tiene que ser JSON. El que subiste es ${fileFormat}`,
                duration: 7000,
                status: 'error',
            })

            setIsLoading(false)
            return
        }

        const fileReader = new FileReader()

        fileReader.addEventListener('loadend', function (event) {
            console.log(event.target.result);
            const fileData = JSON.parse(event.target.result)

            const newData = {
                ...data,
            }

            const fileKeys = Object.keys(fileData)

            const productKeys = {
                bf: [
                    'getgroups',
                    'getfrecsbygroup',
                    'getsessions',
                    'getfrecsbysession',
                    'getreceivedsessions',
                ],
                gf: [
                    'getemotions',
                    'getsessions',
                    'getfrecsbysession',
                    'getreceivedsessions',
                    'getfrecsbyreceivedsession',
                ],
                lb: ['emotions', 'facial', 'local'],
                ol: [
                    'productName',
                    'getemotions',
                    'getsessions',
                    'getfrecsbysession',
                    'getreceivedsessions',
                    'getfrecsbyreceivedsession',
                ],
                se: ['se', 'getgroups', 'getfrecsbygroup'],
            }

            
            for (const [product, keys] of Object.entries(productKeys)) {
                const matchKeys = keys.every(key => {
                    return (fileKeys.includes(key));
                });

                let matchProduct = false;
                console.log(matchKeys, fileData.productName);
                if(matchKeys && fileData.productName === product){
                    matchProduct = true;
                }
           
                if (matchProduct) {
                    setToast({
                        message: `JSON de ${getProductName(
                            product,
                        )} importado correctamente`,
                        duration: 7000,
                        status: 'success',
                    })

                    newData[product] = fileData
                    delete newData[product].productName;
                    setData(newData)
                    setIsLoading(false)
                    break;
                }
            }
        })
        fileReader.readAsText(file[0])
    }

    return (
        <Container speed={50}>

            <Subtitle variant="subtitle">Configuración</Subtitle>

            <SubContainer>
                <Subtitle variant="subtitle">Exportación de JSONs</Subtitle>
                <ProductsContainer>
                    {products.map(({ code }) => (
                        <ProductContainer
                            key={`export-${code}`}
                            onClick={() => {
                                if (exportingSelectedProducts.includes(code)) {
                                    setExportingSelectedProducts(
                                        exportingSelectedProducts.filter(item => item !== code),
                                    )
                                } else {
                                    setExportingSelectedProducts([
                                        ...exportingSelectedProducts,
                                        code,
                                    ])
                                }
                            }}
                        >
                            <ProductImage src={getProductImage(code)} />
                            <ProductName variant="bodylargebold">
                                {getProductName(code)}
                            </ProductName>

                            <ProductCheckbox
                                checked={exportingSelectedProducts.includes(code)}
                                onChange={e => {
                                    if (e.target.checked) {
                                        setExportingSelectedProducts(
                                            exportingSelectedProducts.filter(item => item !== code),
                                        )
                                    } else {
                                        setExportingSelectedProducts([
                                            ...exportingSelectedProducts,
                                            code,
                                        ])
                                    }
                                }}
                            />
                        </ProductContainer>
                    ))}
                </ProductsContainer>

                <ExportJsonsButton hoverAnimation>
                    <ExportJsonsButtonText
                        variant="bodylargebold"
                        onClick={exportingSelectedProducts.length > 0 ? handleExportPress : null}
                        style={{
                            opacity: exportingSelectedProducts.length > 0 ? 1 : 0.4
                          }}
                    >
                        Exportar archivos seleccionados
                    </ExportJsonsButtonText>
                </ExportJsonsButton>
            </SubContainer>

            <SubContainer>
                <Subtitle variant="subtitle">Importación de JSON</Subtitle>
                <Dropzone onDrop={acceptedFiles => setFile(acceptedFiles)} maxFiles={1} accept={{ 'application/json': ['.json'] }}>
                    {({ getRootProps, getInputProps }) => (
                        <DropzoneContainer {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Typography variant="bodylarge">
                                {file
                                    ? file[0].name
                                    : 'Arrastra o clicka para seleccionar un archivo JSON'}
                            </Typography>
                        </DropzoneContainer>
                    )}
                </Dropzone>

                <ExportJsonsButton hoverAnimation>
                    <ExportJsonsButtonText
                        variant="bodylargebold"
                        onClick={!isLoading && file ? handleImportPress: null}
                        style={{
                            opacity: !isLoading && file ? 1 : 0.4
                          }}
                    >
                        Importar archivo seleccionado
                    </ExportJsonsButtonText>
                </ExportJsonsButton>
            </SubContainer>
        </Container>
    )
}

const Container = styled(AnimateChildren)``

const SubContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`

const Subtitle = styled(Typography)`
  margin: 10px 0 10px 0;
  color: ${({ theme }) => theme.palette.whites[3]};
`

const ProductsContainer = styled.div`
  gap: 50px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    gap: 20px;
  }

  @media screen and (max-width: 468px) {
    display: block;
    padding: 0;
  }
`

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  padding: 20px 20px;
  border-radius: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.whites[5]};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.whites[4]};
  }

  @media screen and (max-width: 768px) {
    padding: 20px 10px;
  }
`

const ProductImage = styled.img`
  width: 150px;
`

const ProductName = styled(Typography)`
  text-align: center;
  margin: 10px 0;
`

const ProductCheckbox = styled(Checkbox)`
  margin: 10px 0 0 0;
`

const ExportJsonsButton = styled(Button)``

const ExportJsonsButtonText = styled(Typography)`
  color: ${({ theme }) => theme.palette.alternative};
`

const DropzoneContainer = styled.section`
  margin: 20px 0 20px 0;
  padding: 30px 50px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.alternative};
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.whites[5]};

  &:hover {
    background-color: ${({ theme }) => theme.palette.whites[4]};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.whites[3]};
  }
`

export default Config
