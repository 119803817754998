import Tabs from 'components/tabs'
import Typography from 'components/typography'
import styled from 'styled-components'

import biofoton from 'assets/biofoton.png'
import galaxfamily from 'assets/galaxfamily.png'
import lightbeauty from 'assets/lightbeauty.png'
import odonlight from 'assets/odonlight.png'
import expert from 'assets/expert.png'
import config from 'assets/icon/config.png'

const Header = ({ user }) => {
  const tabs = [
    {
      image: biofoton,
      name: 'Biofoton 2',
      code: 'bf',
      route: '/products/bf',
    },
    {
      image: galaxfamily,
      name: 'Galax Family',
      code: 'gf',
      route: '/products/gf',
    },
    {
      image: lightbeauty,
      name: 'Light Beauty',
      code: 'lb',
      route: '/products/lb',
    },
    {
      image: odonlight,
      name: 'Odon Light',
      code: 'ol',
      route: '/products/ol',
    },
    {
      image: expert,
      name: 'Sistema Experto',
      code: 'se',
      route: '/products/se',
    },
    {
      image: config,
      name: 'Config',
      code: 'cf',
      route: '/config',
    },
  ]

  return (
    <Container>
      <SubContainer>
        <Title variant="title">Biopanel</Title>

        {user ? <Tabs {...{ tabs }} horizontal /> : null}

      </SubContainer>

      <Divider />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`

const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled(Typography)`
  padding: 30px 20px;
  color: ${({ theme }) => theme.palette.whites[4]};
  width: 90px;

  @media screen and (max-width: 468px) {
    font-size: 25px;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.whites[4]};
`

export default Header
