import { useEffect, useState } from 'react'

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'

import { useData } from 'contexts/data'
import { useLanguage } from 'contexts/language'
import {
    getFrecsByProduct,
    getGroupsByProduct,
    getProductSectionData,
    postGroupsByProduct,
    removeGroupsByProduct,
    reorderGroupFrecsByProduct,
} from 'api'

import Box from 'components/box'
import Typography from 'components/typography'
import AnimateChildren from 'react-animate-children'
import TextInput from 'components/textinput'
import Button from 'components/button'

import deleteIcon from 'assets/icon/delete.png'

const ProductGroup = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    const [language] = useLanguage()
    const [data, setData] = useData()

    const { product } = useParams()
    const { state: locationState } = useLocation()
    const navigate = useNavigate()

    const [state, setState] = useState({
        ...locationState,
    })

    const { group, frecs, isNewGroup, section, isSession } = state

    const [isEditing, setIsEditing] = useState(isNewGroup)

    useEffect(() => {
        setTimeout(() => {
            setIsEditing(true)
        }, 500)
    }, [])

    const sectionsData = getProductSectionData(product, data[product])
    const groupsByProduct = getGroupsByProduct(product, section)
    const frecsByProduct = getFrecsByProduct(product, section)
    let enGroup = null;

    if (groupsByProduct && groupsByProduct[1]) {
        /* bf all */

        //enGroup = data[product][groupsByProduct[0]][groupsByProduct[1]]?.en?.find(({ Id }) => Id === group.Id);
        /* ATENCION LOS IDS ESTÁN REPETIDOS Y PUEDE QUE BUSQUE OTRO ERRONEAMENTE EJ. MEDITACION */
        enGroup = data[product][groupsByProduct[0]][groupsByProduct[1]]?.en[group.groupsIndex];
        
    } else {
        /* lb facial
         gf sesiones */

        //enGroup = data[product][groupsByProduct[0]]?.en?.find(({ Id }) => Id === group.Id);
        /* ATENCION LOS IDS ESTÁN REPETIDOS Y PUEDE QUE BUSQUE OTRO ERRONEAMENTE EJ. MEDITACION */
        enGroup = data[product][groupsByProduct[0]]?.en[group.groupsIndex];
    }

    if (isNewGroup && !enGroup) {
        enGroup = {
            ...group,
            Nombre: 'New group',
            Descripcion: 'Description',
            DescripcionAmpliada: 'Large description'
        };
        console.log('new group', group);
        console.log('new enGroup', enGroup);
    }

    console.log('---->data', data);
    console.log('---->product', product);
    console.log('---->groupsByProduct', groupsByProduct);
    console.log('---->frecsByProduct', frecsByProduct);
    console.log('---->enGroup', enGroup);
    console.log('---->Group', group);
    console.log('---->Frecs', frecs);
    console.log('---->section', section);
    console.log('---->sectionsData', sectionsData);

    useEffect(() => {
        const initialValues = ['Nuevo Grupo', 'Descripción', 'Descripción Ampliada']

        const isNewGroup =
            group?.Nombre === initialValues[0] &&
            group?.Descripcion === initialValues[1] &&
            group?.DescripcionAmpliada === initialValues[2]

        if (!isNewGroup) {
            setIsEditing(false)

        }
    }, [])

    useEffect(() => {
        if (frecsByProduct[1]) {
            const newFrecs =
                data[product][frecsByProduct[0]][frecsByProduct[1]][language][
                group.groupsIndex
                ]

            if (JSON.stringify(newFrecs) !== JSON.stringify(frecs)) {
                setState({
                    ...state,
                    group: {
                        ...group,
                        frequencies: newFrecs,
                    },
                    frecs: newFrecs,
                })
                navigate('.', {
                    replace: true,
                    state: {
                        ...state,
                        group: {
                            ...group,
                            frequencies: newFrecs,
                        },
                        newFrecs,
                    },
                })
            }
        }

        if (!frecsByProduct[1]) {
            let newFrecs
            let oldFrecs
            if (product === 'lb') {
                const newFacial = data[product][frecsByProduct[0]][language].findIndex(
                    facial => facial.Nombre === group.Nombre,
                )

                newFrecs =
                    data[product][frecsByProduct[0]][language][newFacial]?.frequencies
                oldFrecs = group?.frequencies
            } else {
                newFrecs = data[product][frecsByProduct[0]][language][group.groupsIndex]
                oldFrecs = frecs
            }

            if (JSON.stringify(newFrecs) !== JSON.stringify(oldFrecs)) {
                setState({
                    ...state,
                    group: {
                        ...group,
                        frequencies: newFrecs,
                    },
                    frecs: newFrecs,
                })
                navigate('.', {
                    replace: true,
                    state: {
                        ...state,
                        group: {
                            ...group,
                            frequencies: newFrecs,
                        },
                        newFrecs,
                    },
                })
            }
        }
    }, [])

    const handleGroupChange = (newGroupValue, dataKey, language) => {
        console.log(newGroupValue, dataKey, language);

        let changedGroup = null;
        let newData = null;

        if (language === 'es') {
            changedGroup = {
                ...group,
                [dataKey]: newGroupValue,
            }
            setState({
                ...state,
                group: changedGroup
            })

        } else if (language === 'en') {
            changedGroup = {
                ...enGroup,
                [dataKey]: newGroupValue,
            }
            setState({
                ...state,
                enGroup: changedGroup,
            })

        }

        console.log('newGroup', changedGroup);

        newData = postGroupsByProduct(
            data,
            product,
            groupsByProduct,
            frecsByProduct,
            language,
            group,
            changedGroup,
        )
        console.log('newData', newData);
        setData(newData)

        /*
        navigate('.', {
            replace: true,
            state: {
                ...state,
                group: changedGroup,
            },
        })
            */
    }

    const handleRemoveGroup = () => {
        if (confirm(`Estás seguro que quieres eliminar ${group.Nombre}?`)) {

            const newData = removeGroupsByProduct(
                data,
                product,
                groupsByProduct,
                frecsByProduct,
                group,
            )
            console.log('delete newData', newData);

            setData(newData)

            navigate(`/products/${product}`, {
                replace: true,
            })
        }
    }

    const handleReorderGroupFrecs = reorderedGroupFrecs => {
        const reorderedGroup = {
            ...group,
            frequencies: reorderedGroupFrecs,
        }

        setState({
            ...state,
            group: reorderedGroup,
            frecs: reorderedGroupFrecs,
        })

        const newData = reorderGroupFrecsByProduct(
            data,
            product,
            groupsByProduct,
            frecsByProduct,
            language,
            group,
            reorderedGroup,
            reorderedGroupFrecs,
        )
        console.log('newData', newData);

        setData(newData)

        navigate('.', {
            replace: true,
            state: {
                ...state,
                group: reorderedGroup,
                frecs: reorderedGroupFrecs,
            },
        })
    }

    const mappedFrecs = (product === 'lb' ? group?.frequencies : frecs)?.filter(
        Boolean,
    )

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    function onDragEnd(result) {
        if (!result.destination) {
            return
        }
        /*
        const newMappedFrecs = reorder(
            mappedFrecs,
            result.source.index,
            result.destination.index,
        )

        newMappedFrecs.forEach((frec, index) => {
            frec.frecIndex = index
            frec.groupIndex = index
        })

        handleReorderGroupFrecs(newMappedFrecs)
        */
        let newData = {
            ...data,
        }

        /*
        const newFrecsES = reorder(
            sectionsData[section]?.frecs?.['es'][group.groupsIndex],
            result.source.index,
            result.destination.index,
        )

        const newFrecsEN = reorder(
            sectionsData[section]?.frecs?.['en'][group.groupsIndex],
            result.source.index,
            result.destination.index,
        )

        setState({
            ...state,
            frecs: newFrecsES,
        });

        if (groupsByProduct[1] && frecsByProduct[1]) {
            // programas y frecuencias
            newData[product][groupsByProduct[0]][groupsByProduct[1]]['es'][group.groupsIndex].frequencies = newFrecsES;
            newData[product][groupsByProduct[0]][groupsByProduct[1]]['en'][group.groupsIndex].frequencies = newFrecsEN;

            newData[product][frecsByProduct[0]][frecsByProduct[1]]['es'][group.groupsIndex] = newFrecsES;
            newData[product][frecsByProduct[0]][frecsByProduct[1]]['en'][group.groupsIndex] = newFrecsEN;

        } else {
            // Sesiones
            newData[product][frecsByProduct[0]]['es'][group.groupsIndex] = newFrecsES;
            newData[product][frecsByProduct[0]]['en'][group.groupsIndex] = newFrecsEN;
            
        }
            */

        let newFrecsES = null;
        let newFrecsEN = null;

        switch (product) {
            case 'lb':
                newFrecsES = reorder(
                    sectionsData[section]?.frecs?.['es'][group.groupsIndex].frequencies,
                    result.source.index,
                    result.destination.index,
                );

                newFrecsEN = reorder(
                    sectionsData[section]?.frecs?.['en'][group.groupsIndex].frequencies,
                    result.source.index,
                    result.destination.index,
                );
        
                newData[product][frecsByProduct[0]]['es'][group.groupsIndex].frequencies = newFrecsES;
                newData[product][frecsByProduct[0]]['en'][group.groupsIndex].frequencies = newFrecsEN;

                setState({
                    ...state,
                    group: newData[product][frecsByProduct[0]]['es'][group.groupsIndex],
                    frecs: newFrecsES,
                });

                break;
        
            default:
                newFrecsES = reorder(
                    sectionsData[section]?.frecs?.['es'][group.groupsIndex],
                    result.source.index,
                    result.destination.index,
                );

                newFrecsEN = reorder(
                    sectionsData[section]?.frecs?.['en'][group.groupsIndex],
                    result.source.index,
                    result.destination.index,
                );

                if (groupsByProduct[1] && frecsByProduct[1]) {
                    // programas y frecuencias
                    newData[product][groupsByProduct[0]][groupsByProduct[1]]['es'][group.groupsIndex].frequencies = newFrecsES;
                    newData[product][groupsByProduct[0]][groupsByProduct[1]]['en'][group.groupsIndex].frequencies = newFrecsEN;

                    newData[product][frecsByProduct[0]][frecsByProduct[1]]['es'][group.groupsIndex] = newFrecsES;
                    newData[product][frecsByProduct[0]][frecsByProduct[1]]['en'][group.groupsIndex] = newFrecsEN;

                } else {
                    // Sesiones
                    newData[product][frecsByProduct[0]]['es'][group.groupsIndex] = newFrecsES;
                    newData[product][frecsByProduct[0]]['en'][group.groupsIndex] = newFrecsEN;
                    
                }

                setState({
                    ...state,
                    frecs: newFrecsES,
                });
        
                break;
        }
        console.log('newData', newData);

        setData(newData);

        /*
        navigate('.', {
            replace: true,
            state: {
                ...state,
                frecs: newFrecsES,
            },
        })*/
    }

    const getItemStyle = (_, draggableStyle) => ({
        ...draggableStyle,
    })

    let sessionTime = '0'

    if (isSession) {
        let totalSeconds = mappedFrecs?.reduce((acc, frec) => {
            return (
                acc +
                Number(
                    frec?.Segments?.reduce((acc, segment) => {
                        return acc + +segment.Duracion
                    }, 0),
                )
            )
        }, 0)

        let hours = Math.floor(totalSeconds / 3600)
        totalSeconds %= 3600
        let minutes = Math.floor(totalSeconds / 60)
        let seconds = totalSeconds % 60

        minutes = String(minutes).padStart(2, '0')
        hours = String(hours).padStart(2, '0')
        seconds = String(seconds).padStart(2, '0')

        if (!isNaN(hours) && !isNaN(minutes) && !isNaN(seconds)) {
            sessionTime = `${hours}:${minutes}:${seconds}`
        }
    }

    const renderTitle = () => {
        return (
            <Flex>
                <Title
                    label="Nombre de grupo español"
                    variant="bodylarge"
                    dataKey="Nombre"
                    onFinish={(value, dataKey) => handleGroupChange(value, dataKey, 'es')}
                    fontSize={16}
                    defaultEditing={isNewGroup}
                    disableEditing={!isEditing}
                    isDisabled={false}
                >
                    {group.Nombre}
                </Title>

                {enGroup?.Nombre ? (
                    <Title
                        label="Nombre de grupo inglés"
                        variant="bodylarge"
                        dataKey="Nombre"
                        onFinish={(value, dataKey) =>
                            handleGroupChange(value, dataKey, 'en')
                        }
                        fontSize={16}
                        defaultEditing={isNewGroup}
                        disableEditing={!isEditing}
                        isDisabled={false}
                    >
                        {enGroup?.Nombre}
                    </Title>
                ) : null}
            </Flex>
        )
    }

    const renderDescription = () => {

        return (
            <>
                <Flex>
                    {!isSession ? (
                        <>
                            <Subtitle
                                label={
                                    group.Descripcion
                                        ? 'Descripción de grupo español'
                                        : 'Archivo de grupo español'
                                }
                                variant="bodylarge"
                                dataKey={
                                    group.Descripcion
                                        ? 'Descripcion'
                                        : group.Archivo
                                            ? 'Archivo'
                                            : ''
                                }
                                onFinish={(value, dataKey) => handleGroupChange(value, dataKey, 'es')}
                                fontSize={16}
                                defaultEditing={isNewGroup}
                                disableEditing={!isEditing}
                            >
                                {group.Descripcion || group.Archivo}
                            </Subtitle>

                            {enGroup?.Descripcion || enGroup?.Archivo ? (
                                <Subtitle
                                    label={
                                        enGroup?.Descripcion
                                            ? 'Descripción de grupo inglés'
                                            : 'Archivo de grupo inglés'
                                    }
                                    variant="bodylarge"
                                    dataKey={
                                        enGroup?.Descripcion
                                            ? 'Descripcion'
                                            : enGroup?.Archivo
                                                ? 'Archivo'
                                                : ''
                                    }
                                    onFinish={(value, dataKey) =>
                                        handleGroupChange(value, dataKey, 'en')
                                    }
                                    fontSize={16}
                                    defaultEditing={isNewGroup}
                                    disableEditing={!isEditing}
                                >
                                    {enGroup?.Descripcion || enGroup?.Archivo}
                                </Subtitle>
                            ) : null}
                        </>
                    ) : null}
                </Flex>

                <div
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        gap: 20,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >

                    {group?.DescripcionAmpliada || group?.Observaciones ? (
                        <Description
                            label={
                                typeof group?.DescripcionAmpliada === 'string'
                                    ? 'Descripción Ampliada de grupo español'
                                    : 'Observaciones de grupo español'
                            }
                            variant="bodylarge"
                            dataKey={
                                typeof group?.DescripcionAmpliada === 'string'
                                    ? 'DescripcionAmpliada'
                                    : typeof group?.Observaciones === 'string'
                                        ? 'Observaciones'
                                        : ''
                            }
                            onFinish={(value, dataKey) => handleGroupChange(value, dataKey, 'es')}
                            fontSize={16}
                            defaultEditing={isNewGroup}
                            disableEditing={!isEditing}
                        >
                            {group?.DescripcionAmpliada || group?.Observaciones || '_'}
                        </Description>
                    ) : null}

                    {enGroup?.DescripcionAmpliada || enGroup?.Observaciones ? (
                        <Description
                            label={
                                typeof enGroup?.DescripcionAmpliada === 'string'
                                    ? 'Descripción Ampliada de grupo inglés'
                                    : 'Observaciones de grupo inglés'
                            }
                            variant="bodylarge"
                            dataKey={
                                typeof enGroup?.DescripcionAmpliada === 'string'
                                    ? 'DescripcionAmpliada'
                                    : typeof enGroup?.Observaciones === 'string'
                                        ? 'Observaciones'
                                        : ''
                            }
                            onFinish={(value, dataKey) => handleGroupChange(value, dataKey, 'en')}
                            fontSize={16}
                            defaultEditing={isNewGroup}
                            disableEditing={!isEditing}
                        >
                            {enGroup?.DescripcionAmpliada || enGroup?.Observaciones || '_'}
                        </Description>
                    ) : null}
                </div>
            </>
        )
    }

    const renderGroupFrecs = () => {
        const groupFrecs = mappedFrecs?.map(({ Nombre, Id, ...rest }, index) => {
            const { frecIndex, ...restWithoutIndex } = rest
            let nombreEN = 'No disponible';
            let nombreES = 'No disponible';

            switch (product) {
                case 'lb':
                    nombreES = Nombre;
                    nombreEN =  data[product][frecsByProduct[0]]['en'][group.groupsIndex].frequencies[index]?.Nombre;
                    break;
            
                default:
                    nombreES = Nombre;
                    nombreEN = (frecsByProduct[1] ? data[product][frecsByProduct[0]][frecsByProduct[1]]['en'][group.groupsIndex][index]?.Nombre : data[product][frecsByProduct[0]]['en'][group.groupsIndex][index]?.Nombre);
                    break;
            }
         
            return (
                <Draggable
                    key={`${index}-${Id}`}
                    draggableId={`${index}-${Id}`}
                    // isDragDisabled={true}
                    index={index}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                            )}
                        >
                            <Link
                                to={`/products/${product}/group/${group.groupFrecsIndex}/frec/${index}`}
                                state={{
                                    group,
                                    frec: {
                                        Nombre,
                                        Id,
                                        frecIndex: index,
                                        ...restWithoutIndex,
                                    },
                                    section,
                                    isSession,
                                }}
                            >
                                <FrecsTitle variant="bodylarge">{nombreES}</FrecsTitle>
                                <EnFrecsTitle variant="bodylarge">
                                    &nbsp;|&nbsp;
                                    {nombreEN}
                                </EnFrecsTitle>

                            </Link>
                        </div>
                    )}
                </Draggable>
            )
        })

        return groupFrecs
    }

    const renderTotales = () => {
        let totES = 0;
        let totEN = 0;
        switch (product) {
            case 'lb':
                totES = data[product][frecsByProduct[0]]['es'][group.groupsIndex]?.frequencies?.length;
                totEN = data[product][frecsByProduct[0]]['en'][group.groupsIndex]?.frequencies?.length;
                break;
        
            default:
                totES = frecs?.length;
                totEN = (frecsByProduct[1] ? data[product][frecsByProduct[0]][frecsByProduct[1]]['en'][group.groupsIndex]?.length : data[product][frecsByProduct[0]]['en'][group.groupsIndex]?.length);
                break;
        }
        return <TotalCount variant="span">Totales: {totES}&nbsp;|&nbsp;{totEN}</TotalCount>
    }

    return (
        <Container speed={50}>
            <FrecRemoveButton
                onClick={() => handleRemoveGroup()}
                noBorder
                hoverAnimation
            >
                <FrecRemoveImg src={deleteIcon} alt="delete-frec" />
            </FrecRemoveButton>

            <ContentForm>
                {renderTitle()}
                {renderDescription()}
            </ContentForm>

            <SubGroupTitle variant="bodylarge">Subgrupos</SubGroupTitle>
            <FrecsContainer>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {renderGroupFrecs()}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <Link
                    to={`/products/${product}/group/${group.groupFrecsIndex}/frec/new`}
                    state={{
                        group,
                        section,
                        frec: {
                            Id:
                                Number(
                                    [...new Set(mappedFrecs?.map(({ Id }) => Id))].sort((a, b) => a - b).at(-1),
                                ) + 1 || 0,
                            frecIndex: mappedFrecs?.length ? mappedFrecs?.length : 0,
                            Nombre: '',
                            Summary: '',
                            Mode: '0',
                            Segundos: '0',
                            Trama: '',
                            Imagen: '',
                            Segments: [],
                        },
                        isNewFrec: true,
                        isSession,
                    }}
                >
                    <FrecsTitle variant="bodylarge">+</FrecsTitle>
                </Link>

                {isSession ? (
                    <Typography variant="span">Tiempo total: {sessionTime}</Typography>
                ) : null}

                {renderTotales()}

            </FrecsContainer>
        </Container>
    )
}

const Container = styled(AnimateChildren)`
  position: relative;
`

const FrecRemoveButton = styled(Button)`
  position: relative;
  top: 0px;
  left: calc(100% - 50px);
  width: 60px;
  height: 60px;
`

const FrecRemoveImg = styled.img`
   float: right;
  filter: ${({ theme }) => theme.palette.filters.alternative};
  width: 19px;
  height: 19px;
`

const SubGroupTitle = styled(Typography)`
  padding: 10px 0;
`

const Title = styled(TextInput)`
  padding-bottom: 5px;
`

const Subtitle = styled(TextInput)`
  padding-bottom: 5px;
`

const Description = styled(TextInput)`
  padding-bottom: 5px;
`

const ContentForm = styled(Box)`
  flex-wrap: wrap;
`

const FrecsContainer = styled(Box)`
  flex-wrap: wrap;
  /* display: flex; */
`

const FrecsTitle = styled(Typography)`
  margin: 5px;
display: inline-block;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.palette.alternative};
  }
`

const EnFrecsTitle = styled(Typography)`
    display: inline-block;
    color: ${({ theme }) => theme.palette.whites[4]};
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  margin: 15px 0;
`

const TotalCount = styled(Typography)`
    color: ${({ theme }) => theme.palette.whites[4]};
`

export default ProductGroup
