import { useState } from 'react'

import styled from 'styled-components'

import { useToast } from 'contexts/toast'

import AnimateChildren from 'react-animate-children'
import Box from 'components/box'
import Typography from 'components/typography'
import TextInput from 'components/textinput'
import Button from 'components/button'
import { useNavigate } from 'react-router-dom'
import { useUser } from 'contexts/user'

const Login = () => {
  const [password, setPassword] = useState('')

  const [, setToast] = useToast()

  const [, setUser] = useUser()

  const navigate = useNavigate()

  const handleLoginPress = () => {
    if (password !== 's1lv3rs4lud') {
      setToast({
        message: 'La contraseña no es correcta',
        duration: 7000,
        status: 'error',
      })
      return
    }

    setUser(true)
    navigate('/products/bf')
  }

  return (
    <Container speed={50}>
      <Typography variant="title">Login</Typography>

      <LoginContainer>
        <Typography variant="bodylarge">Introduce la constraseña</Typography>

        <LoginInput
          onFinish={e => setPassword(e) & handleLoginPress()}
          onChange={e => setPassword(e)}
          password
        >
          ********
        </LoginInput>

        <LoginButton variant="bodylargebold" onClick={handleLoginPress}>
          Login
        </LoginButton>
      </LoginContainer>
    </Container>
  )
}

const Container = styled(AnimateChildren)`
  padding: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoginContainer = styled(Box)`
    width: 25rem;
  height: 20rem;
  padding: 50px;
  margin-top: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const LoginInput = styled(TextInput)`
  color: white;
  font-size: 20px;
  border-bottom: 1px solid white;
`

const LoginButton = styled(Button)`
  color: ${({ theme }) => theme.palette.alternative};
  font-weight: bold;
  padding: 15px 50px;
  font-size: 20px;
`

export default Login
