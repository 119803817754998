import React from 'react'

import styled from 'styled-components'

/**
 * * typography renders a text with a specific variant
 * * @param {variant} type of text => [title, subtitle, sectiontitle, body, bodybold, bodylink, bodylarge, bodylargebold, button, span]
 * * @param {children} text to render
 */

const title = styled.h1`
  font-family: EncodeSansExpanded Black;
  font-size: 50px;
  color: ${({ theme }) => theme.palette.dark};
  margin: 0;
  color: white;
`

const subtitle = styled.h2`
  font-family: EncodeSansExpanded Bold;
  font-size: 35px;
  color: ${({ theme }) => theme.palette.dark};
  margin: 0;
  color: white;
`

const sectiontitle = styled.h3`
  font-family: EncodeSansExpanded Regular;
  font-size: 20px;
  margin: 0;
  color: white;
`

const body = styled.p`
  font-family: EncodeSansExpanded Regular;
  font-size: 14px;
  margin: 0;
  color: white;
`

const bodybold = styled.p`
  font-family: EncodeSansExpanded Bold;
  font-size: 12px;
  margin: 0;
  color: white;
`

const bodylink = styled.p`
  font-family: EncodeSansExpanded Bold;
  font-size: 12px;
  margin: 0;
  color: white;
`

const bodylarge = styled.p`
  font-family: EncodeSansExpanded Regular;
  font-size: 16px;
  margin: 0;
  color: white;
`

const bodylargebold = styled.p`
  font-family: EncodeSansExpanded Bold;
  font-size: 16px;
  margin: 0;
  color: white;
`

const button = styled.p`
  font-family: EncodeSansExpanded Bold;
  font-size: 14px;
  margin: 0;
  color: white;
`

const span = styled.p`
  font-family: EncodeSansExpanded Regular;
  font-size: 11px;
  margin: 0;
  color: white;
`

const TypographyVariants = {
  title,
  subtitle,
  sectiontitle,
  body,
  bodybold,
  bodylink,
  bodylarge,
  bodylargebold,
  button,
  span,
}

const Typography = ({
  variant = 'body',
  children,
  className = '',
  ...rest
}) => {
  const Text = TypographyVariants[variant]

  const text = Array.isArray(children)
    ? children
        .map(string => string)
        .flat()
        .join('')
    : children

  return (
    <Text className={className} {...{ ...rest }}>
      {text?.toString()}
    </Text>
  )
}

export default Typography
