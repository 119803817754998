import { useEffect, useState } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import styled from 'styled-components'

import { getFrecsByProduct, getGroupsByProduct, postFrecByProduct, removeFrecByProduct } from 'api'
import { useSimpleFrecs } from 'contexts/simplefrecs'
import { useLanguage } from 'contexts/language'
import { useData } from 'contexts/data'

import Box from 'components/box'
import AnimateChildren from 'react-animate-children'
import TextInput from 'components/textinput'
import Typography from 'components/typography'
import Button from 'components/button'
import Tabs from 'components/tabs'

import deleteIcon from 'assets/icon/delete.png'

const ProductFrec = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    const [language] = useLanguage()
    const [data, setData] = useData()
    const [simpleFrecs, setSimpleFrecs] = useSimpleFrecs()

    const { product, frec_id } = useParams()

    const { state: locationState } = useLocation()
    const navigate = useNavigate()

    const [state, setState] = useState({
        ...locationState,
    })

    useEffect(() => {
        navigate('.', {
            replace: true,
            state: {
                ...locationState,
            },
        })

        setState({ ...locationState })
    }, [frec_id])

    let { group, frec, section, isNewFrec, isSimpleFrec, isSession } = state

    const [isEditing, setIsEditing] = useState(isNewFrec)

    const frecsByProduct = getFrecsByProduct(product, section)
    const groupsByProduct = getGroupsByProduct(product, section);

    let enFrec = null;

    /* biofoton si tiene doble, el resto no */
    /* para biofoton esta lógica está bien pero para galax family no xq data no tiene la misma estructura... */


    switch (product) {
        case 'lb':
            if (group) {
                enFrec = data[product][frecsByProduct[0]]?.en[group.groupsIndex].frequencies[frec.frecIndex];

            } else {
                enFrec = data[product][frecsByProduct[0]]?.en[frec.frecIndex];
            }
            
            break;

        default:
            /* bf and gf */
            if (frecsByProduct[1]) {
                /* for programs and frecuencies */
                /* ATENCION LOS IDS ESTÁN REPETIDOS Y PUEDE QUE BUSQUE OTRO ERRONEAMENTE EJ. MEDITACION */
                // enGroup = data[product][groupsByProduct[0]][groupsByProduct[1]]?.en[group.groupsIndex];
                enFrec = data[product][frecsByProduct[0]][frecsByProduct[1]]?.en[group.groupsIndex][frec.frecIndex];
            } else {
                /* for sessions */
                /* ATENCION LOS IDS ESTÁN REPETIDOS Y PUEDE QUE BUSQUE OTRO ERRONEAMENTE EJ. MEDITACION */
                if (group) {
                    enFrec = data[product][frecsByProduct[0]]?.en[group.groupsIndex][frec.frecIndex];
                } else {
                    // Por ejemplo para galax family emociones no pertenece a un grupo
                    enFrec = data[product][frecsByProduct[0]]?.en[frec.frecIndex];
                }
            }
            break;
    }

    if (isNewFrec && !enFrec) {
        enFrec = {
            ...frec,
            Nombre: 'Name',
            Summary: 'Description'
        };
    }

    console.log('---->data', data);
    console.log('---->product', product);
    console.log('---->frecsByProduct', frecsByProduct);
    console.log('---->groupsByProduct', groupsByProduct);
    console.log('---->Group', group);
    console.log('---->frec', frec);
    console.log('---->enFrec', enFrec);
    console.log('---->section', section);
    console.log('---->isNewFrec', isNewFrec);

    useEffect(() => {
        setTimeout(() => {
            setIsEditing(true)
        }, 500)
    }, [])

    const tabs = [
        {
            name: 'Programas',
            route: '',
            groups: data?.bf?.getgroups?.programs[language]
                .flat()
                .map((group, groupIndex) => {
                    return {
                        ...group,
                        frecs: data?.bf?.getfrecsbygroup?.programs[language][groupIndex],
                    }
                }),
        },
        {
            name: 'Frecuencias',
            route: '',
            groups: data?.bf?.getgroups?.frecuencies[language]
                .flat()
                .map((group, groupIndex) => {
                    return {
                        ...group,
                        frecs: data?.bf?.getfrecsbygroup?.frecuencies[language][groupIndex],
                    }
                }),
        },
        {
            name: 'Sesiones',
            route: '',
            groups: data?.bf?.getsessions[language]
                .flat()
                .map((group, groupIndex) => {
                    return {
                        ...group,
                        frecs: data?.bf?.getfrecsbysession[language][groupIndex],
                    }
                }),
        },
        {
            name: 'Frecuencias Simples',
            route: '',
            frecs: simpleFrecs?.frecs[language],
        },
    ]

    const updateLocalState = newState => {
        setState({
            ...state,
            ...newState,
        })

        navigate('.', {
            replace: true,
            state: {
                ...state,
                ...newState,
            },
        })
    }

    useEffect(() => {
        return
        if (!isNewFrec) {
            if (!group) {
                const newFrec =
                    data[product][frecsByProduct[0]][language][frec.frecIndex]

                newFrec &&
                    updateLocalState({
                        frec: newFrec,
                    })
            }

            if (group) {
                if (frecsByProduct[1]) {
                    const newFrec =
                        data[product][frecsByProduct[0]][frecsByProduct[1]][language][
                        group.groupFrecsIndex
                        ][frec.frecIndex]

                    console.log(newFrec)

                    newFrec &&
                        updateLocalState({
                            frec: newFrec,
                        })
                }

                if (!frecsByProduct[1]) {
                    const newFrec =
                        data[product][frecsByProduct[0]][language][group.groupFrecsIndex][
                        frec.frecIndex
                        ]

                    newFrec &&
                        updateLocalState({
                            frec: newFrec,
                        })
                }
            }
        }
    }, [])

    const getSegmentsRefs = () => {
        if (frec?.Segments) {
            const newFrec = {
                ...frec,
                Segments: frec.Segments.map((segment, index) => {
                    const reference = (simpleFrecs?.frecs[language] || []).find(
                        ({ hz }) => {
                            return (
                                Number(hz.replaceAll(',', '.')) ===
                                Number(segment.frec.replaceAll(',', '.')) ||
                                hz === segment.frec
                            )
                        },
                    )?.Nombre

                    return {
                        ...segment,
                        ref: reference,
                    }
                }),
            }

            updateLocalState({
                frec: newFrec,
            })
        }
    }

    useEffect(() => {
        getSegmentsRefs()
    }, [])

    const handleFrecChange = async (
        newFrecValue,
        dataKey,
        language,
        multipleValuesAndKeys,
        callback
    ) => {

        const newDuration = await frec?.Segments?.reduce(
            (acc, segment) => acc + +segment.Duracion,
            0,
        )

        let changedFrec = null;
        let newData = null;

        if (language === 'es') {
            console.log('multipleValuesAndKeys', multipleValuesAndKeys);

            if (multipleValuesAndKeys) {
                /* ESTO SIRVE CUANDO SE HACEN CAMBIOS CON LAS TABS
                const newFrecValues = multipleValuesAndKeys.reduce(
                    (acc, [value, key]) => {
                        acc[key] = value
                        return acc
                    },
                    {},
                )
                changedFrec = {
                    ...frec,
                    Segundos: newDuration,
                    ...newFrecValues,
                }
                */
            } else {
                changedFrec = {
                    ...frec,
                    Segundos: newDuration,
                    [dataKey]: newFrecValue,
                }
            }

            setState({
                ...state,
                frec: changedFrec,
            })
            console.log('changedFrec', changedFrec);

            if (product === 'fs') {
                /* NO SABEMOS PARA QUE SIRVE FS
                newData = postFrecByProduct(
                    {
                        fs: simpleFrecs,
                    },
                    product,
                    frecsByProduct,
                    language,
                    group,
                    frec,
                    changedFrec,
                );

                setSimpleFrecs(newData.fs)
                */
            } else {
                newData = postFrecByProduct(
                    data,
                    product,
                    frecsByProduct,
                    groupsByProduct,
                    language,
                    group,
                    frec,
                    changedFrec,
                );
                console.log('newData', newData);
                setData(newData);
            }

        } else if (language === 'en') {

            changedFrec = {
                ...enFrec,
                Segundos: newDuration,
                [dataKey]: newFrecValue,
            };
            console.log('EN changedFrec', changedFrec);

            setState({
                ...state,
                enFrec: changedFrec,
            });
            console.log('EN frec', frec);
            newData = postFrecByProduct(
                data,
                product,
                frecsByProduct,
                groupsByProduct,
                language,
                group,
                frec,
                changedFrec,
            );
            console.log('EN newData', newData);
            setData(newData);

        }

        if (callback) {
            callback({
                ...state,
                frec: changedFrec,
            })
        }

        /*
        navigate('.', {
            replace: true,
            state: {
                ...state,
                frec: changedFrec,
            },
        })
            */
    }

    const handleSegmentChange = (newFrecValue, dataKey, segmentsIndex) => {
        const newSegments = [...frec.Segments]

        newSegments[segmentsIndex][dataKey] = newFrecValue.toString()

        const newFrec = {
            ...frec,
            Segments: newSegments,
        }

        setState({
            ...state,
            frec: newFrec,
        })

        const newData = postFrecByProduct(
            data,
            product,
            frecsByProduct,
            groupsByProduct,
            language,
            group,
            frec,
            newFrec,
        )

        setData(newData)

        navigate('.', {
            replace: true,
            state: {
                ...state,
                frec: newFrec,
            },
        })
    }

    const [isRemoving, setIsRemoving] = useState(false)

    const handleSegmentRemove = segmentsIndex => {
        setIsRemoving(segmentsIndex)

        setIsRemoving(null)

        const newSegments = [...frec.Segments]

        newSegments.splice(segmentsIndex, 1)

        const newFrec = {
            ...frec,
            Segments: newSegments,
        }

        setState({
            ...state,
            frec: newFrec,
        })

        const newData = postFrecByProduct(
            data,
            product,
            frecsByProduct,
            groupsByProduct,
            language,
            group,
            frec,
            newFrec,
        )

        setData(newData)

        navigate('.', {
            replace: true,
            state: {
                ...state,
                frec: newFrec,
            },
        })
    }

    const handleAddSegment = (newSegments, replace, newState) => {
        const { frec } = newState || state
        const oldSegments = replace ? [] : [...frec.Segments]

        const mergedSegments = [...oldSegments, ...newSegments].map(segment => {
            const reference = (simpleFrecs?.frecs[language] || []).find(({ hz }) => {
                return (
                    Number(hz.replaceAll(',', '.')) ===
                    Number(segment.frec.replaceAll(',', '.')) || hz === segment.frec
                )
            })?.Nombre

            return {
                ...segment,
                ref: reference,
            }
        })

        const newSecs = mergedSegments?.reduce(
            (acc, segment) => acc + +segment.Duracion,
            0,
        )

        const newFrec = {
            ...frec,
            Segundos: newSecs,
            Segments: mergedSegments,
        }

        updateLocalState({
            frec: newFrec,
        })

        const newData = postFrecByProduct(
            data,
            product,
            frecsByProduct,
            groupsByProduct,
            language,
            group,
            frec,
            newFrec,
        )

        setData(newData)

        navigate('.', {
            replace: true,
            state: {
                ...state,
                frec: newFrec,
            },
        })
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleRemoveFrec = () => {
        if (
            confirm(
                `Estás seguro que quieres eliminar ${frec?.Nombre ||
                `esta frecuencia`}?`,
            )
        ) {
            const newData = removeFrecByProduct(
                data,
                product,
                frecsByProduct,
                groupsByProduct,
                group,
                frec,
            )
            console.log('delete newData', newData);

            /* NO SABEMOS PARA QUE SE USA
            if (!group) {
                setData(newData)
                navigate(`/products/${product}`, {
                    replace: true,
                    state: {
                        frecs: newData,
                        section,
                        isSession,
                    },
                })

                return
            }
                */

            let changedGroup = null;
            let urlNav = null;

            switch (product) {
                case 'lb':
                    changedGroup = newData[product][groupsByProduct[0]][language][group.groupsIndex];
                    setState({
                        ...state,
                        group: changedGroup
                    })

                    setData(newData);

                    urlNav = (group ? `/products/${product}/group/${group.groupsIndex}` : `/products/${product}`);

                    navigate(urlNav, {
                        replace: true,
                        state: {
                            group: {
                                ...group,
                                frequencies: changedGroup.frequencies,
                            },
                            frecs: changedGroup.frequencies,
                            section,
                            isSession,
                        }
                    })
                    break;

                default:
                    if (groupsByProduct && groupsByProduct[1]) {
                        /* for programs and frecuencies */
                        changedGroup = newData[product][groupsByProduct[0]][groupsByProduct[1]][language][group.groupsIndex];

                        if (frecsByProduct[1]) {
                            changedGroup = newData[product][frecsByProduct[0]][frecsByProduct[1]][language][group.groupsIndex];
                        }

                    } else {
                        /* for sessions */
                        if (group) {
                            changedGroup = newData[product][frecsByProduct[0]][language][group.groupsIndex];
                        } else {
                            changedGroup = newData[product][frecsByProduct[0]][language][frec.frecIndex];
                        }

                    }
                    setState({
                        ...state,
                        group: changedGroup
                    })

                    setData(newData);

                    urlNav = (group ? `/products/${product}/group/${group.groupsIndex}` : `/products/${product}`);

                    navigate(urlNav, {
                        replace: true,
                        state: {
                            group: {
                                ...group,
                                frequencies: changedGroup,
                            },
                            frecs: changedGroup,
                            section,
                            isSession,
                        }
                    })
                    break;
            }

            /* NO SABEMOS PORQUE ESTÁ
            
            let newGroup = null;
            if (groupsByProduct[1]) {
                
                newGroup = newData[product][groupsByProduct[0]][groupsByProduct[1]][language][group.groupsIndex];

                if (frecsByProduct[1]){
                    newGroup = newData[product][frecsByProduct[0]][frecsByProduct[1]][language][group.groupFrecsIndex];
                }

            } else {
              
                newGroup = newData[product][frecsByProduct[0]][language][group.groupFrecsIndex];
            }
            console.log('newGroup', newGroup);

            setData(newData)

            navigate(`/products/${product}/group/${group.groupFrecsIndex}`, {
                replace: true,
                state: {
                    group: {
                        ...group,
                        frequencies: product === 'lb' ? newGroup.frequencies : newGroup,
                    },
                    frecs: product === 'lb' ? newGroup.frequencies : newGroup,
                    section,
                    isSession,
                },
            })
                */
        }
    }

    const handleReorderFrecSegments = newSegments => {
        const newFrec = {
            ...frec,
            Segments: newSegments,
        }

        setState({
            ...state,
            frec: {
                ...state.frec,
                Segments: newSegments,
            },
        })

        const newData = postFrecByProduct(
            data,
            product,
            frecsByProduct,
            groupsByProduct,
            language,
            group,
            frec,
            newFrec,
        )

        setData(newData)

        navigate('.', {
            replace: true,
            state: {
                ...state,
                frec: newFrec,
            },
        })
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    function onDragEnd(result) {
        if (!result.destination) {
            return
        }

        const newSegments = reorder(
            frec?.Segments,
            result.source.index,
            result.destination.index,
        )

        handleReorderFrecSegments(newSegments)
    }

    const getItemStyle = (_, draggableStyle) => ({
        ...draggableStyle,
        height: '35px',
    })

    const getmode = mode => {
        switch (mode) {
            case 'Led + Laser':
                return 0
            case 'Laser':
                return 1
            case 'Led':
                return 2
            case 'Aux':
                return 3
            case 'Aux + Led':
                return 6
            case 'Aux + Laser':
                return 7

            default:
                break
        }
    }

    const getModeFromNumber = modenumber => {
        switch (modenumber) {
            case 0:
                return 'Led + Laser'
            case 1:
                return 'Laser'
            case 2:
                return 'Led'
            case 3:
                return 'Aux'
            case 6:
                return 'Aux + Led'
            case 7:
                return 'Aux + Laser'

            default:
                break
        }
    }

    const renderTitle = () => {
        return (
            <Flex>
                <Title
                    label="Nombre en español"
                    variant="bodylarge"
                    dataKey="Nombre"
                    onFinish={(value, dataKey) => handleFrecChange(value, dataKey, 'es')}
                    fontSize={16}
                    defaultEditing={isNewFrec && !isSession}
                    disableEditing={isSimpleFrec || !isEditing}
                    isDisabled={false}
                >
                    {frec?.Nombre || 'Nombre'}
                </Title>

                <Title
                    label="Nombre en inglés"
                    variant="bodylarge"
                    dataKey="Nombre"
                    onFinish={(value, dataKey) => handleFrecChange(value, dataKey, 'en')}
                    fontSize={16}
                    defaultEditing={isNewFrec && !isSession}
                    disableEditing={isSimpleFrec || !isEditing}
                    isDisabled={false}
                >
                    {enFrec?.Nombre || 'Name'}
                </Title>
            </Flex>
        )
    }

    const renderSubtitle = () => {
        return (
            <div
                style={{
                    marginTop: 20,
                    marginBottom: 20,
                    gap: 20,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Subtitle
                    label={
                        (typeof frec?.Summary === 'string'
                            ? 'Summary'
                            : typeof frec?.Descripcion === 'string'
                                ? 'Descripción'
                                : 'Descripción Ampliada') + ' en español'
                    }
                    variant="bodylarge"
                    dataKey={
                        typeof frec?.Summary === 'string'
                            ? 'Summary'
                            : typeof frec?.Descripcion === 'string'
                                ? 'Descripcion'
                                : 'DescripcionAmpliada'
                    }
                    onFinish={(value, dataKey) => handleFrecChange(value, dataKey, 'es')}
                    fontSize={16}
                    defaultEditing={isNewFrec && !isSession}
                    disableEditing={isSimpleFrec || !isEditing}
                >
                    {frec?.Summary || frec?.Descripcion || 'Descripción'}
                </Subtitle>

                <Subtitle
                    label={
                        (typeof enFrec?.Summary === 'string'
                            ? 'Summary'
                            : typeof enFrec?.Descripcion === 'string'
                                ? 'Descripción'
                                : 'Descripción Ampliada') + ' en inglés'
                    }
                    variant="bodylarge"
                    dataKey={
                        typeof enFrec?.Summary === 'string'
                            ? 'Summary'
                            : typeof enFrec?.Descripcion === 'string'
                                ? 'Descripcion'
                                : 'DescripcionAmpliada'
                    }
                    onFinish={(value, dataKey) => handleFrecChange(value, dataKey, 'en')}
                    fontSize={16}
                    defaultEditing={isNewFrec && !isSession}
                    disableEditing={isSimpleFrec || !isEditing}
                >
                    {enFrec?.Summary || enFrec?.Descripcion || 'Description'}
                </Subtitle>
            </div>
        )
    }

    const renderImage = () => {
   
        return (
            <Flex>
                <Description
                    label="Imagen en español"
                    variant="bodylarge"
                    dataKey="Imagen"
                    onFinish={(value, dataKey) => handleFrecChange(value, dataKey, 'es')}
                    fontSize={16}
                    defaultEditing={isNewFrec && !isSession}
                >
                    {frec?.Imagen || '-'}
                </Description>

                <Description
                    label="Imagen en inglés"
                    variant="bodylarge"
                    dataKey="Imagen"
                    onFinish={(value, dataKey) => handleFrecChange(value, dataKey, 'en')}
                    fontSize={16}
                    defaultEditing={isNewFrec && !isSession}
                >
                    {enFrec?.Imagen || '-'}
                </Description>
            </Flex>
        )
    }

    const isExpert = product === 'se'

    const renderTabsContainer = () => {
        return (
            <TabsContainer>
                <SubGroupTitle variant="bodylarge">Agregar segmento</SubGroupTitle>
                <Tabs
                    {...{ tabs, isSession }}
                    handleClickPress={(newSegments, parentFrec, isAddingWholeSession) => {
                        // isSession => the frec being edited is a session
                        // isAddingWholeSession => the frec being added is a whole session
                        if (isSession) {
                            handleFrecChange(
                                null,
                                null,
                                'es',
                                [
                                    [parentFrec.Nombre, 'Nombre'],
                                    [parentFrec.Summary, 'Descripcion'],
                                ],
                                newState => {
                                    handleAddSegment(
                                        newSegments.map(segment => segment),
                                        true,
                                        newState,
                                    )
                                },
                            )
                        } else if (isAddingWholeSession) {
                            if (isExpert && newSegments[0][0].session) {
                                setIsEditing(false)
                                handleFrecChange(
                                    null,
                                    null,
                                    'es',
                                    [
                                        [newSegments[0][0].session.Nombre, 'Nombre'],
                                        [newSegments[0][0].session.Observaciones, 'Descripcion'],
                                        [6, 'Mode'],
                                    ],
                                    newState => {
                                        handleAddSegment(
                                            newSegments.map(segment => segment[0]),
                                            false,
                                            newState,
                                        )

                                        setTimeout(() => {
                                            setIsEditing(true)
                                        }, 1000)
                                    },
                                )
                            } else handleAddSegment(newSegments.map(segment => segment[0]))
                        } else {
                            if (isExpert && parentFrec) {
                                setIsEditing(false)
                                handleFrecChange(
                                    null,
                                    null,
                                    'es',
                                    [
                                        [parentFrec.Nombre, 'Nombre'],
                                        [parentFrec.Summary, 'Descripcion'],
                                        [parentFrec.Mode, 'Mode'],
                                    ],
                                    newState => {
                                        handleAddSegment(
                                            newSegments.map(segment => segment),
                                            false,
                                            newState,
                                        )

                                        setTimeout(() => {
                                            setIsEditing(true)
                                        }, 1000)
                                    },
                                )
                            } else handleAddSegment(newSegments.map(segment => segment))
                        }
                    }}
                />
            </TabsContainer>
        );
    }

    return (
        <Container speed={50}>

            {renderTabsContainer()}

            <Subcontainer>

                {!isSimpleFrec ? (
                    <FrecRemoveButton
                        onClick={() => handleRemoveFrec()}
                        noBorder
                        hoverAnimation
                        float
                    >
                        <FrecRemoveImg src={deleteIcon} alt="delete-frec" />
                    </FrecRemoveButton>
                ) : null}

                <ContentForm>
                    {renderTitle()}
                    {renderImage()}
                    {renderSubtitle()}

                    {frec?.Mode ? (
                        <>
                            <Typography variant="bodylarge">Modo: </Typography>
                            <Description
                                variant="bodylarge"
                                dataKey="Mode"
                                onFinish={(value, dataKey) => handleFrecChange(value, dataKey, 'es')}
                                fontSize={16}
                                defaultEditing={isNewFrec && !isSession}
                                disableEditing={isSimpleFrec || !isEditing}
                                value={frec?.Mode}
                                options={[
                                    { value: 0, name: 'Led + Laser' },
                                    { value: 1, name: 'Laser' },
                                    { value: 2, name: 'Led' },
                                    { value: 3, name: 'Aux' },
                                    { value: 6, name: 'Aux + Led' },
                                    { value: 7, name: 'Aux + Laser' },
                                ]}
                            >
                                {getModeFromNumber(Number(frec?.Mode))}
                            </Description>
                        </>
                    ) : null}
                </ContentForm>

                <SubGroupTitle variant="bodylarge">Segmentos</SubGroupTitle>
                <ContentForm>

                    {frec?.Segments ? (
                        <>
                            <FrecsContainer p={0} m={0}>
                                <IndicatorsContainer>
                                    {[
                                        'HZ',
                                        'Referencia',
                                        'Subgrupo',
                                        'Duracion',
                                        'Canal',
                                        'Amplitud',
                                    ].map((value, index) => (
                                        <FrecSubContainer
                                            key={`section-group-${value}-segments-column`}
                                            width={index === 1 || index === 2 ? '22.5%' : '10%'}
                                        >
                                            <Typography variant="bodylargebold">{value}</Typography>
                                        </FrecSubContainer>
                                    ))}
                                    <div
                                        style={{
                                            width: '2.2%',
                                        }}
                                    ></div>
                                </IndicatorsContainer>

                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {provided => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {frec?.Segments?.map(
                                                    (
                                                        {
                                                            Canal,
                                                            frec: hz,
                                                            amp,
                                                            Duracion,
                                                            ref = '',
                                                            subgroup = '',
                                                        },
                                                        segmentsIndex,
                                                    ) => {
                                                        return (
                                                            <Draggable
                                                                key={`${segmentsIndex}-${hz}-${Duracion}`}
                                                                draggableId={`${segmentsIndex}-${hz}-${Duracion}`}
                                                                index={segmentsIndex}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style,
                                                                        )}
                                                                    >
                                                                        <FrecContainer
                                                                            p={0}
                                                                            m={0}
                                                                            key={`section-group-${frec?.Nombre}-segments-${hz}-${Duracion}-${segmentsIndex}`}
                                                                            className={
                                                                                isRemoving === segmentsIndex
                                                                                    ? 'hidden'
                                                                                    : ''
                                                                            }
                                                                        >
                                                                            {[
                                                                                hz,
                                                                                ref,
                                                                                subgroup,
                                                                                Duracion,
                                                                                Canal,
                                                                                amp,
                                                                            ].map((value, index) => (
                                                                                <FrecSubContainer
                                                                                    key={`section-group-${frec?.Nombre}-segments-${hz}-${Duracion}-${index}`}
                                                                                    width={
                                                                                        index === 1 || index === 2
                                                                                            ? '22.5%'
                                                                                            : '10%'
                                                                                    }
                                                                                >
                                                                                    <FrecText
                                                                                        variant="bodylarge"
                                                                                        width="45%"
                                                                                        disableEditing={
                                                                                            [
                                                                                                'frec',
                                                                                                'ref',
                                                                                                'subgroup',
                                                                                                'Duracion',
                                                                                                'Canal',
                                                                                                'amp',
                                                                                            ][index] === 'ref' ||
                                                                                            [
                                                                                                'frec',
                                                                                                'ref',
                                                                                                'subgroup',
                                                                                                'Duracion',
                                                                                                'Canal',
                                                                                                'amp',
                                                                                            ][index] === 'subgroup'
                                                                                        }
                                                                                        dataKey={
                                                                                            [
                                                                                                'frec',
                                                                                                'ref',
                                                                                                'subgroup',
                                                                                                'Duracion',
                                                                                                'Canal',
                                                                                                'amp',
                                                                                            ][index]
                                                                                        }
                                                                                        type="number"
                                                                                        onFinish={(value, dataKey) =>
                                                                                            handleSegmentChange(
                                                                                                value,
                                                                                                dataKey,
                                                                                                segmentsIndex,
                                                                                            )
                                                                                        }
                                                                                        fontSize={14}
                                                                                    >
                                                                                        {value}
                                                                                    </FrecText>
                                                                                </FrecSubContainer>
                                                                            ))}

                                                                            <SegRemoveButton
                                                                                noBorder
                                                                                hoverAnimation
                                                                                onClick={() =>
                                                                                    handleSegmentRemove(segmentsIndex)
                                                                                }
                                                                            >
                                                                                <FrecRemoveImg
                                                                                    src={deleteIcon}
                                                                                    alt="delete-frec"
                                                                                />
                                                                            </SegRemoveButton>
                                                                        </FrecContainer>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    },
                                                )}

                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </FrecsContainer>
                        </>
                    ) : null}

                    <>
                        <Typography variant="span">Tiempo total: {frec?.Segments?.reduce((acc, segment) => {
                            return acc + +segment.Duracion
                        }, 0)}</Typography>
                    </>
                </ContentForm>
            </Subcontainer>
        </Container>
    )
}



const Container = styled(AnimateChildren)`
  position: relative;
  display: flex;
  width: 100%;

  * > {
    &.hidden {
      animation: hide 1s ease;
      animation-fill-mode: forwards;
    }

    &.show {
      animation: show 1s ease;
    }
  }

  > * {
    &:first-child {
      width: 23%;
    }

    &:nth-child(2) {
      width: 77%;
    }
  }
`

const TabsContainer = styled.div`
    padding-top: 20px;
`

const TabsSubContainer = styled.div`
display: flex;
  flex-wrap: wrap;
`

const Subcontainer = styled.div`
  width: 100%;
  > * {
    width: 100%;
  }
  &:first-child {
    width: 100%;
  }
`

const Title = styled(TextInput)`
  padding-bottom: 5px;
`

const Subtitle = styled(TextInput)`
  padding-bottom: 5px;
`

const Description = styled(TextInput)`
  padding-bottom: 5px;
`

const FrecsContainer = styled.div`
  margin-top: 10px;
`

const FrecContainer = styled(Box)`
  /* margin-top: 20px; */
  display: flex;
  flex-wrap: wrap;
  background-color: transparent;
  justify-content: space-around;
`

const FrecSubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  width: ${({ width }) => width || '100%'};
  height: fit-content;
`

const FrecText = styled(TextInput)`
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  max-width: 100%;
`

const FrecRemoveButton = styled(Button)`
  position: relative;
  top: 0px;
  left: calc(100% - 50px);
  width: 60px;
  height: 60px;
`

const SegRemoveButton = styled(Button)`
  position: ${({ float }) => (float ? 'absolute' : 'relative')};
  top: ${({ float }) => (float ? '-20px' : 'auto')};
  left: ${({ float }) => (float ? '95%' : 'auto')};
  width: ${({ float }) => (float ? '60px' : 'auto')};
  height: ${({ float }) => (float ? '60px' : 'auto')};
  padding: ${({ float }) => (float ? '10px 20px' : '0')};
`

const FrecRemoveImg = styled.img`
  filter: ${({ theme }) => theme.palette.filters.alternative};
  width: 19px;
  height: 19px;
`

const IndicatorsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  margin: 15px 0;
`

const ContentForm = styled(Box)`
  flex-wrap: wrap;
`

const SubGroupTitle = styled(Typography)`
  padding: 10px 0;
`


export default ProductFrec
