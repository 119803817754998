import { Route, BrowserRouter as Router, Routes } from 'react-router-dom' // HashRouter for Electron, BrowserRouter for Web

import styled from 'styled-components'

import { useUser } from 'contexts/user'

import ProductDashboard from 'screens/product-dashboard'
import ProductGroup from 'screens/product-group'
import ProductFrec from 'screens/product-frec'
import Frequencies from 'screens/frequencies'
import Config from 'screens/config'
import Login from 'screens/login'

import Header from 'components/header'
import AnimateChildren from 'react-animate-children'

const Navigation = () => {
  const [user] = useUser()

  const routes = !user
    ? [
        <Route key={`route-/login`} path="/" element={<Login />} />,
        <Route key={`route-/login`} path="*" element={<Login />} />,
      ]
    : [
        <Route
          key={`route-/products/:product`}
          path="/products/:product"
          element={<ProductDashboard />}
        />,
        <Route
          key={`route-/products/:product/group/:group_id`}
          path="/products/:product/group/:group_id"
          element={<ProductGroup />}
        />,
        <Route
          key={`route-/products/:product/group/:group_id/frec/:frec_id`}
          path="/products/:product/group/:group_id/frec/:frec_id"
          element={<ProductFrec />}
        />,
        <Route key={`route-/frecs`} path="/frecs" element={<Frequencies />} />,
        <Route key={`route-/config`} path="/config" element={<Config />} />,
      ]

  return (
    <Background>
      <Container speed={50}>
        <Router>
          <Header {...{ user }} />

          <Body>
            <RouteContainer>
              <Routes>{routes.map(route => route)}</Routes>
            </RouteContainer>
          </Body>
        </Router>
      </Container>
    </Background>
  )
}

const Background = styled.div`
  background-image: linear-gradient(to bottom right, rgb(0,5,20), rgba(0,30,100));
  background-repeat: no-repeat;
  min-height: 100vh;
`

const Container = styled(AnimateChildren)``

const Body = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    display: block;
  }
`

const RouteContainer = styled.div`
  padding: 20px;
  flex: 1;

  @media screen and (max-width: 768px) {
    padding: 20px 20px;
  }
`

export default Navigation
