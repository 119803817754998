import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useLanguage } from 'contexts/language'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Box from 'components/box'
import Typography from 'components/typography'
import AnimateChildren from 'react-animate-children'
import {
    getFrecsByProduct,
    getGroupsByProduct,
    getProductSectionData,
    getProductSections,
    postFrecByProduct,
    reorderGroupsByProduct,
} from 'api'
import { useData } from 'contexts/data'

const Sections = ({ product: { code, data: productData } }) => {
    const [language] = useLanguage()
    const [data, setData] = useData()

    const sections = getProductSections(code)

    let sectionsData = getProductSectionData(code, productData)

    console.log('productData', productData);
    console.log('sectionsData', sectionsData);

    const newDynamicFields = [
        {
            Nombre: 'Nuevo Grupo',
            Descripcion: 'Descripción',
            DescripcionAmpliada: 'Descripción Ampliada',
        },
        {
            Nombre: 'Nueva Sesión',
            Observaciones: 'Observaciones',
        },
    ]

    const isSession = section => section === 'Sesiones' || section === 'Facial'

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    const onDragEnd = ({ draggableId, destination, source, ...rest }) => {
        try {
            if (code !== 'bf') return alert('Solo se puede reordenar en Biofoton')
            const section = sections.find(section => draggableId.includes(section))

            console.log('draggableId -----> ', draggableId);
            console.log('destination -----> ', destination);
            console.log('source -----> ', source);

            // const sectionGroups = sectionsData[section]?.groups?.[language]?.filter(
            //   Boolean,
            // )

            // const [fromGroup, fromGroupIndex] = [
            //   sectionGroups[source.index],
            //   source.index,
            // ]

            // const fromGroupFrecs = sectionsData[section]?.frecs?.[language]?.filter(
            //   Boolean,
            // )[fromGroupIndex]

            // const from = {
            //   group: fromGroup,
            //   groupIndex: fromGroupIndex,
            //   frecs: fromGroupFrecs,
            // }

            // const [toGroup, toGroupIndex] = [
            //   sectionGroups[destination.index],
            //   destination.index,
            // ]

            // const toGroupFrecs = sectionsData[section]?.frecs?.[language]?.filter(
            //   Boolean,
            // )[toGroupIndex]

            // const to = {
            //   group: toGroup,
            //   groupIndex: toGroupIndex,
            //   frecs: toGroupFrecs,
            // }

            let newData = {
                ...data,
            }
            console.log('sectionsData', sectionsData);
            /* hay que actualizar los grupos y las frecuencias a la vez */

            const newGroupsES = reorder(
                sectionsData[section]?.groups?.['es'],
                source.index,
                destination.index,
            )

            const newFrecsES = reorder(
                sectionsData[section]?.frecs?.['es'],
                source.index,
                destination.index,
            )

            const newGroupsEN = reorder(
                sectionsData[section]?.groups?.['en'],
                source.index,
                destination.index,
            )

            const newFrecsEN = reorder(
                sectionsData[section]?.frecs?.['en'],
                source.index,
                destination.index,
            )

            const originalGroupsKeys = getGroupsByProduct(code, section)
            const originalFrecsKeys = getFrecsByProduct(code, section)
            console.log('originalGroupsKeys', originalGroupsKeys);

            console.log('originalFrecsKeys', originalFrecsKeys);

            if (originalGroupsKeys[1]) {
                // programas y frecuencias
                newData[code][originalGroupsKeys[0]][originalGroupsKeys[1]]['es'] = newGroupsES;
                newData[code][originalFrecsKeys[0]][originalFrecsKeys[1]]['es'] = newFrecsES;
                newData[code][originalGroupsKeys[0]][originalGroupsKeys[1]]['en'] = newGroupsEN;
                newData[code][originalFrecsKeys[0]][originalFrecsKeys[1]]['en'] = newFrecsEN;

            } else if (originalGroupsKeys[0]) {
                // Sesiones
                newData[code][originalGroupsKeys[0]]['es'] = newGroupsES;
                newData[code][originalFrecsKeys[0]]['es'] = newFrecsES;
                newData[code][originalGroupsKeys[0]]['en'] = newGroupsEN;
                newData[code][originalFrecsKeys[0]]['en'] = newFrecsEN;
            }
            console.log('newData', newData);

            setData(newData)
            sectionsData = getProductSectionData(code, newData)
        } catch (error) {
            console.log(error)
        }
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,
        paddinBottom: isDragging ? 1 : 1.5,
    })

    return (
        <Container speed={50}>
            {sections.map(section => (
                <div key={`sections-${code}-${section}`}>
                    <Title variant="bodylarge">{section}</Title>

                    <GroupsContainer>

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {provided => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {sectionsData[section]?.groups?.[language]
                                            ? sectionsData[section]?.groups[language]
                                                ?.filter(Boolean)
                                                .map(({ Nombre, Id, ...rest }, index) => (
                                                    <Draggable
                                                        key={`section-group-${code}-${section}-${Nombre}`}
                                                        draggableId={`section-group-${code}-${section}-${Nombre}`}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style,
                                                                )}
                                                            >
                                                                <Link
                                                                    key={`section-group-${code}-${section}-${Nombre}`}
                                                                    to={`/products/${code}/group/${index}`}
                                                                    state={{
                                                                        section,
                                                                        group: {
                                                                            Nombre,
                                                                            Id,
                                                                            ...rest,
                                                                            groupFrecsIndex: index,
                                                                            groupsIndex: index,
                                                                        },
                                                                        frecs:
                                                                            sectionsData[section]?.frecs?.[
                                                                            language
                                                                            ][index] || [],
                                                                        isSession: isSession(section),
                                                                    }}
                                                                >
                                                                    <GroupTitle variant="bodylarge">
                                                                        {Nombre} 
                                                                    </GroupTitle>
                                                                    <EnGroupTitle variant="bodylarge">&nbsp;|&nbsp; {sectionsData[section]?.groups['en'][index]?.Nombre || 'No disponible'}</EnGroupTitle>
                                                                </Link>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))
                                            : sectionsData[section]?.frecs[language]
                                                ?.filter(Boolean)
                                                .map(({ Nombre, Id, ...rest }, index) => (
                                                    <Draggable
                                                        key={`section-frec-${code}-${section}-${Nombre}`}
                                                        draggableId={`section-frec-${code}-${section}-${Nombre}`}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style,
                                                                )}
                                                            >
                                                                <Link
                                                                    key={`section-frec-${code}-${section}-${Nombre}`}
                                                                    to={`/products/${code}/group/-1/frec/${Id ||
                                                                        index}`}
                                                                    state={{
                                                                        section,
                                                                        frec: {
                                                                            Nombre,
                                                                            Id,
                                                                            frecIndex: index,
                                                                            ...rest,
                                                                        },
                                                                        isSimpleFrec:
                                                                            section === 'Frecuencias Simples',
                                                                    }}
                                                                >
                                                                    <GroupTitle variant="bodylarge">
                                                                        {Nombre}
                                                                    </GroupTitle>
                                                                    <EnGroupTitle variant="bodylarge">&nbsp;|&nbsp; {sectionsData[section]?.frecs['en'][index]?.Nombre || 'No disponible'}</EnGroupTitle>
                                                                </Link>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                        {sectionsData[section]?.groups?.[language] ? (
                            <Link
                                to={`/products/${code}/group/new`}
                                state={{
                                    section,
                                    isNewGroup: true,
                                    isSession: isSession(section),
                                    group: {
                                        ...newDynamicFields[isSession(section) ? 1 : 0],
                                        Id:
                                            Number(
                                                [
                                                    ...new Set(
                                                        sectionsData[section]?.groups?.[language]
                                                            ?.filter(Boolean)
                                                            .map(({ Id }) => Id),
                                                    ),
                                                ]
                                                    .sort((a, b) => a - b)
                                                    .at(-1),
                                            ) + 1 || 0,
                                        groupFrecsIndex: sectionsData[section]?.groups[language]
                                            .length
                                            ? sectionsData[section]?.groups[language].length
                                            : 0,
                                        groupsIndex: sectionsData[section]?.groups[language].length
                                            ? sectionsData[section]?.groups[language].length
                                            : 0,
                                    },
                                    frecs: [],
                                }}
                            >
                                <GroupTitle variant="bodylarge">+</GroupTitle>
                            </Link>
                        ) : (
                            <Link
                                to={`/products/${code}/group/-1/frec/new`}
                                state={{
                                    section,
                                    group: null,
                                    isNewFrec: true,
                                    frec: {
                                        Nombre: '',
                                        Summary: '',
                                        Id: '',
                                        Code: '',
                                        Imagen: '',
                                        Mode: '0',
                                        Segments: [],
                                        Segundos: '0',
                                        frecIndex: sectionsData[section]?.frecs[language].length,
                                    },
                                }}
                            >
                                <GroupTitle variant="bodylarge">+</GroupTitle>
                            </Link>
                        )}

                        {sectionsData[section]?.groups ? (
                            <TotalCount variant="span">Totales: {sectionsData[section]?.groups['es']?.length || '0'}&nbsp;|&nbsp;{sectionsData[section]?.groups['en']?.length || '0'}</TotalCount>
                        ) : (
                            <TotalCount variant="span">Totales: {sectionsData[section]?.frecs['es']?.length || '0'}&nbsp;|&nbsp;{sectionsData[section]?.frecs['en']?.length || '0'}</TotalCount>
                        )}
                    </GroupsContainer>
                </div>
            ))}
        </Container>
    )
}

const Container = styled(AnimateChildren)``

const Title = styled(Typography)`
  padding: 10px 0;
`

const GroupsContainer = styled(Box)`
  flex-wrap: wrap;
  /* display: flex; */
`

const GroupTitle = styled(Typography)`
  margin: 5px;
    display: inline-block;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.palette.alternative};
  }
`

const EnGroupTitle = styled(Typography)`
    display: inline-block;
    color: ${({ theme }) => theme.palette.whites[4]};
`

const TotalCount = styled(Typography)`
    color: ${({ theme }) => theme.palette.whites[4]};
`

export default Sections
